import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
// import Chat from "@material-ui/icons/Chat";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ExtensionIcon from '@material-ui/icons/Extension';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Technical Documentation</h2>
          <h5 className={classes.description}>
            {/* This is the paragraph where you can write more details about your
            product. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more. */}
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="about-yimba" className={classes.link}>
              <InfoArea
                title="What is Yimba?"
                description="Click here to find out more about Yimba and what services we can provide."
                icon={MonetizationOnIcon}
                iconColor="info"
                vertical
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <a href='https://www.npmjs.com/package/yimba-sdk'>
              <InfoArea
                title="Yimba SDK"
                description="Click here to view technical documentation about our SDK and find out how to integrate into your new or existing app development."
                icon={CloudDoneIcon}
                iconColor="success"
                vertical
              />
            </a>
            <GridContainer justify="center">
              <Button color="success" href="/yimba-demo.mp4" target="_blank" rel="noopener noreferrer">See it in Action</Button>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="api-docs" className={classes.link}>
              <InfoArea
                title="Yimba API"
                description="Click here to find out more about direct integration into the Yimba service via our API's."
                icon={ExtensionIcon}
                iconColor="warning"
                vertical
              />
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
