import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
// import Chat from "@material-ui/icons/Chat";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import BrushIcon from '@material-ui/icons/Brush';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>API Documentation</h2>
          <h5 className={classes.description}>
            {/* This is the paragraph where you can write more details about your
            product. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more. */}
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="api-docs/yimba-security" className={classes.link}>
              <InfoArea
                title="Yimba Security Service"
                description="The Yimba Security Service manages your access to the Yimba API's and will issue a bearer token that is valid for a 24 hour period."
                icon={FingerprintIcon}
                iconColor="info"
                vertical
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="api-docs/yimba-card" className={classes.link}>
              <InfoArea
                title="Yimba Card Service"
                description="The Yimba Card Service features the methods that are available for you to interact with all card based elements of the Yimba Service. Within this service you will find methods for listing all cards within your card program, registering a new card, getting and setting the design identifier for a card as well as obtaining the design history for a given card."
                icon={CreditCardIcon}
                iconColor="success"
                vertical
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="api-docs/yimba-design" className={classes.link}>
              <InfoArea
                title="Yimba Design Service"
                description="The Yimba Design Service features methods that are available for you to explore all design
                elements of the Yimba Service. Within this service you will find methods to list all available
                designs for your card program(s)."
                icon={BrushIcon}
                iconColor="warning"
                vertical
              />
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
