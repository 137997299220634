import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Overview from "assets/img/process.svg";
import SPayCode from "assets/img/spaycode.png"
import CardArts from "assets/img/cardarts.png"
import Error from "assets/img/error.png"

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Process Overview</h2>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ color: '#333333' }}>
        SPay will follow the usual procedure for provisioning a card via the issuer. The Token Metadata is received as normal and passed to the ResponseDataBuilder. 
        The ResponseDataBuilder then builds the CardArt array as usual from the CardArts Class.
          <br></br>
          <br></br>
          SPay will have registered the card on the Yimba Platform allowing for available Designs to be made available to be injected into the SPay CardArts Class. 
          By following this approach, it is estimated that this will require a minimal amount of code changes to SPay and create a solid integration between SPay and Yimba
        </div>
        <br></br>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h3 className={classes.title}>Proposed Integration</h3>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={Overview} height='650' alt="Overview of Proposed Integration"/>
        </div>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h3 className={classes.title}>Example SPay Changes</h3>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={SPayCode} height='520' alt="Overview of Proposed Integration"/>
        </div>
      </GridContainer>
      <GridContainer>
        <div style={{ color: '#333333' }}>
        <br></br>
        <br></br>
        The above code example is taken from the <strong>ResponseDataBuilder</strong> class within SPay. The <strong>ResponseDataBuilder</strong> class above shows that Card product and type can be determined within the 
        <strong> tokenResponseData</strong>. This will help determine if the card being provisioned has the ability to be able to change card art or not as defined by the issuer.
        A proposed approach for integrating Yimba into SPay would be to make the Yimba Card Designs available to the existing <strong>CardArts</strong> class. 
        <br></br>
        <br></br>
        As Yimba will return the URL for the chosen design, the design could be downloaded in the same manner as from the
        <strong> CardIssuerApp.setDownloadUrl</strong> method in the above example and then written to the device storage using the <strong>	android.os.Parcel</strong> class from the OS
        as used in the <strong>cardArts2.setFd</strong> method as above.
        </div>
      </GridContainer>
      <GridContainer>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={CardArts} height='390' alt="Overview of Proposed Integration"/>
        </div>
      </GridContainer>
      <GridContainer>
        <div style={{ color: '#333333' }}>
        <br></br>
        <br></br>
        The <strong>CardArts</strong> class within SPay has pre-defined variables to hold the MIME Type for various image formats. The Yimba Platform can return images in multiple formats
        depending on availability of content within a Card Program. Some of the content returned will be animated in GIF format which can be supported by the <strong>CardArts</strong> class.
        </div>
      </GridContainer>
      <GridContainer>
        <div style={{ color: '#333333' }}>
        <br></br>
        <br></br>
        Wherever Samsung chooses to implement the route into Yimba, we recommend that this is handled via a call to action button that can be shown or hidden from the Samsung Pay UI based on the Yimba
        Card Registration Process. If a card is registered successfully on a fully configured card programme, the card registration will be returned as per the technical documentation. When a card is
        not registered sucessfully due to the BIN not being configured correctly, the following error message will be displayed:-
        </div>
      </GridContainer>
      <GridContainer>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={Error} height='100' alt="Failed Card Registration"/>
        </div>
      </GridContainer>
      <GridContainer>
        <div style={{ color: '#333333' }}>
        <br></br>
        <br></br>
        In this is instance, the call to action can be hidden or not rendered in the Samsung Pay UI or shown clearly when a card is sucessfully registered.
        </div>
      </GridContainer>
    </Fragment>
  );
}