import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import BuildIcon from '@material-ui/icons/Build';
import ContactlessIcon from '@material-ui/icons/Contactless';
import Apple from '@material-ui/icons/Apple';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { useAuth0 } from "../../../react-auth0-spa";

const useStyles = makeStyles(styles);

export default function ProductSection() {
    const classes = useStyles();
    const { user } = useAuth0();
    let showAdditionalContent = false;
    let showAppleContent=false;
    let showGoogleContent=false;

    if (user) {
        if (user.name === 'samsung@yimba.com') {
            showAdditionalContent = true;
        }
        if (user.name === 'apidocs@yimba.com') {
            showAdditionalContent = true;
        }
        if (user.name === 'apple@yimba.com') {
            showAppleContent = true;
        }
        if (user.name === 'google@yimba.com') {
            showGoogleContent = true;
        }
    }

    return (
        <Fragment>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Get Setup</h2>
                        <h5 className={classes.description}>
                            {/* This is the paragraph where you can write more details about your
            product. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more. */}
                        </h5>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Link to="get-setup" className={classes.link}>
                                <InfoArea
                                    title="Setup & Security Overview"
                                    description="Click here to find out more about getting setup with Yimba and understanding our security."
                                    icon={BuildIcon}
                                    iconColor="primary"
                                    vertical
                                />
                            </Link>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            {showAdditionalContent ? <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Samsung Pay Integration</h2>
                        <h5 className={classes.description}>
                            {/* This is the paragraph where you can write more details about your
        product. Keep you user engaged by providing meaningful information.
        Remember that by this time, the user is curious, otherwise he wouldn
        {"'"}t scroll to get here. Add a button if you want the user to see
        more. */}
                        </h5>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Link to="samsung" className={classes.link}>
                                <InfoArea
                                    title="Proposed Integration Approach"
                                    description="Click Here to find out more information and gain an overview of a proposed integration approach to integrate the Yimba Platform into SPay's existing framework and process."
                                    icon={ContactlessIcon}
                                    iconColor="info"
                                    vertical
                                />
                            </Link>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                    </GridContainer>
                </div>
            </div> : null}
            {showAppleContent ? <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Apple Pay Integration</h2>
                        <h5 className={classes.description}>
                            {/* This is the paragraph where you can write more details about your
        product. Keep you user engaged by providing meaningful information.
        Remember that by this time, the user is curious, otherwise he wouldn
        {"'"}t scroll to get here. Add a button if you want the user to see
        more. */}
                        </h5>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Link to="apple" className={classes.link}>
                                <InfoArea
                                    title="Proposed Integration Approach"
                                    description="Click Here to find out more information and gain an overview of a proposed integration approach to integrate the Yimba Platform into Apple Pay's existing framework and process."
                                    icon={Apple}
                                    iconColor="gray"
                                    vertical
                                />
                            </Link>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                    </GridContainer>
                </div>
            </div> : null}
            {showGoogleContent ? <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Google Pay Integration</h2>
                        <h5 className={classes.description}>
                            {/* This is the paragraph where you can write more details about your
        product. Keep you user engaged by providing meaningful information.
        Remember that by this time, the user is curious, otherwise he wouldn
        {"'"}t scroll to get here. Add a button if you want the user to see
        more. */}
                        </h5>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Link to="google" className={classes.link}>
                                <InfoArea
                                    title="Proposed Integration Approach"
                                    description="Click Here to find out more information and gain an overview of a proposed integration approach to integrate the Yimba Platform into G Pay's existing framework and process."
                                    icon={ContactlessIcon}
                                    iconColor="info"
                                    vertical
                                />
                            </Link>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                    </GridContainer>
                </div>
            </div> : null}
        </Fragment>
    );
}
