import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Overview from "assets/img/gpayprocess.png";
import SPayCode from "assets/img/paycardart.png"

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Process Overview</h2>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ color: '#333333' }}>
          The exiting G Pay code can follow the usual procedure for provisioning a card via the issuer. The tokenised card metadata is received as normal and passed to the IssuerData Class.
          The default card image is then created by the PayCardArtCreator class and written to the device storage.
          <br></br>
          <br></br>
          The G Pay application will register the provisioned card on the Yimba Platform allowing for available Designs to be made available to the G Pay Application via the PayCardArt Class.
          By following this approach, it is estimated that this will require a minimal amount of code changes to the G Pay application and create a solid integration between G Pay and Yimba
        </div>
        <br></br>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h3 className={classes.title}>Proposed Integration</h3>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={Overview} height='650' alt="Overview of Proposed Integration" />
        </div>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h3 className={classes.title}>Example G Pay Modifications</h3>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={SPayCode} height='520' alt="Overview of Proposed Integration" />
        </div>
      </GridContainer>
      <GridContainer>
        <div style={{ color: '#333333' }}>
          <br></br>
          <br></br>
        The above code example is taken from the <strong>PayCardArt</strong> class within G Pay. The <strong>PayCardArt</strong> class above shows that G Pay has a method already developed
        for handling the Card Art as provided by the issuer. The <strong>PayCardArt</strong> class could be extended to interact with the Yimba Platform to enable the customisation to take
        place and returned inside the <strong>PayCardArtInfo</strong> class when a payment is made or the card is displayed.
        <br></br>
          <br></br>
        As Yimba will return the URL for the chosen design, the design could be downloaded in the same manner as from the
        <strong>PayCardArtCreator</strong> class and then written to the device storage using the <strong>android.os.Parcel</strong> class from the OS
        as used in the <strong>PayCardArtCreator</strong> class.
        </div>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h3 className={classes.title}>Important Notice</h3>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
          <div style={{ color: '#333333' }}>
            <br></br>
        One of the principle elements of the design and development of the Yimba platform is the ability for the integration to take place during the existing integration with the card issuer.
        This is primarily to ensure that banks still have the ability to control updates to cards via the standard token expiry method. As the issuer data is received from the network as shown
        in the diagram above, it should be noted that the intended approach will still allow the issuing party the ability to reset or update the card art on demand as required.
        </div>
        </GridContainer>
    </Fragment>
  );
}