import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Yimba Card Service</h2>
          </GridItem>
        </GridContainer>
      </div>
      <SwaggerUI url="https://raw.githubusercontent.com/yimba-ltd/API-Docs/master/Yimba-Card.yaml" />
    </Fragment>
  );
}