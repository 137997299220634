import React from "react";
import { Router, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import LandingPage from "views/LandingPage/LandingPage.js";
import APIDocs from "views/APIDocs/APIDocs.js";
import YimbaSecurity from "views/APIDocs/YimbaSecurity/YimbaSecurity.js";
import YimbaCard from "views/APIDocs/YimbaCard/YimbaCard.js";
import YimbaDesign from "views/APIDocs/YimbaDesign/YimbaDesign.js";
import WhatIs from "views/WhatIsYimba/WhatIs.js";
import SetupOverview from "views/SetupOverview/Setup.js";
import Samsung from "views/Samsung/SPayOverview.js";
import Apple from "views/Apple/ApplePayOverview.js";
import Google from "views/Google/GPayOverview.js";

// styles
import "./App.css";
import "assets/scss/material-kit-react.scss?v=1.8.0";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { loading, user } = useAuth0();
  let showAdditionalContent;
  let showAppleContent;
  let showGoogleContent;

  if (loading) {
    return <Loading />;
  }

  if (user) {
    if (user.name === 'samsung@yimba.com') {
      showAdditionalContent = true;
    }
    if (user.name === 'apple@yimba.com') {
      showAppleContent = true;
    }
    if (user.name === 'google@yimba.com') {
      showGoogleContent = true;
    }
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        {/* <NavBar /> */}
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <PrivateRoute path="/" exact component={LandingPage} />
            <PrivateRoute path="/get-setup" exact component={SetupOverview} />
            <PrivateRoute path="/about-yimba" component={WhatIs} />
            <PrivateRoute path="/api-docs/yimba-design" component={YimbaDesign} />
            <PrivateRoute path="/api-docs/yimba-card" component={YimbaCard} />
            <PrivateRoute path="/api-docs/yimba-security" component={YimbaSecurity} />
            <PrivateRoute path="/api-docs" component={APIDocs} />
            {showAdditionalContent ? <PrivateRoute path="/samsung" component={Samsung} /> : null}
            {showAppleContent ? <PrivateRoute path="/apple" component={Apple} /> : null}
            {showGoogleContent ? <PrivateRoute path="/google" component={Google} /> : null}
          </Switch>
        </Container>
        {/* <Footer /> */}
      </div>
    </Router>
  );
};

export default App;
