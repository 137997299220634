import React from "react";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function BrandBar() {
    return (
        <div>
            <Link to="/">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <img alt="Coffee and Code" style={{ marginTop: -600, height: 100, marginLeft: -20 }} src={require("assets/img/yimba.png")} />
                        <h3 style={{ marginTop: -250 }}>
                            <img style={{ height: 250 }} src='https://s3.eu-west-2.amazonaws.com/yimba.images/coffee.png' alt="coffee header" />
                        </h3>
                        <br />
                    </GridItem>
                </GridContainer>
            </Link>
        </div>
    )
}