import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import InitialArtwork from "assets/img/pkpassdefaultcard.png"
import Overview from "assets/img/appleoverview.png";
import Example from "assets/img/examplecard.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Integration Overview</h2>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ color: '#333333' }}>
          Apple Pay can follow the usual procedure for provisioning a card via the issuer. The Token Metadata is received as normal and using the existing PKPASS format, a custom
          image can be set in the normal provisioning of the card.
          <br></br>
          <br></br>
          During the initial card provisioning, the default card art is downloaded to the device via the PKPASS format in a given object structure, e.g:-
          <br>
          </br>
          <br>
          </br>
          <img src={InitialArtwork} height='132' alt="Initial Card Art" />
          <br>
          </br>
          <br>
          </br>
          At this point, custom artwork may be set during the card provisiong process by suppling the pass file a preset custom image of choice.
        </div>
        <br></br>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h3 className={classes.title}>PKPASS Usage</h3>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ color: '#333333' }}>
          Passes are a digital representation of information that otherwise exists in a physical medium such as plastic or paper cards and tickets. They let users take
          an action in the physical world. Passes can contain images and other supporting data as well as other key functionality to support Yimba's platform such as
          Push Notifications. The pass library contains the user’s passes and are viewed and used via the Apple Wallet app.
        </div>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={Overview} height='300' alt="Overview of Proposed Integration" />
        </div>
        <div style={{ color: '#333333' }}>
          A PKPASS file can be updated on demand within the existing structure of the Apple Wallet. The Card Image file stored within the pass can be changed on demand as required
          and shown in the example below. Through the Yimba platform, it should therefore be possible for a user to select a custom image and have this applied to the stored card
          within their Apple Wallet. This process can take place without modifying the existing card metadata.
        </div>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={Example} height='300' alt="Overview of Proposed Integration" />
        </div>
      </GridContainer>
      <GridContainer>
        <div style={{ color: '#333333', marginLeft: 'auto', marginRight: 'auto' }}>
          Example Screenshot from Apple Wallet with replaced Card Art
        </div>
      </GridContainer>
      <br></br>
      <br></br>
      <GridContainer>
        <div style={{ color: '#333333' }}>
          By using this approach, it allows for Banks and Issuers to override the card art on demand when required as expired tokens would therefore trigger an image update via
          the originating url data.
        </div>
      </GridContainer>
    </Fragment>
  );
}