import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Security from "assets/img/security.png";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Get set up with Yimba</h2>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ color: '#333333' }}>
          In order to use the Yimba SDK, you will need to have been issued your unique <strong>Authentication Identification,
          Client Identification and Passkey</strong>. You <strong>will not</strong> be able to make use of the Yimba SDK without these elements
          being issued by Yimba.
          <br></br>
          <br></br>
          Your application must first call the Yimba Authentication Service passing both your AuthenticationID and
          PassKey with the request. The authentication service will issue a bearer token that will be valid for 24 hours.
          The Token Request method (requestToken) will return a promise holding the token data for you to manage within
          your application.
          <br></br>
          <br></br>
          When loading the SDK, we require <strong>Authentication Identification, Client Identification, Passkey and Bank/Card
          Program reference number</strong>. We will check if the card program has been set up, and only display images/assets
          that have been setup for that program. If the card program has not been setup, we will return a message
          ‘Card Program not setup’ which will allow you to decide what you show to the user.
        </div>
        <br></br>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h3 className={classes.title}>Security Overview</h3>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 0 }}>
          <img src={Security} height='400' alt="Overview of Yimba Security"/>
        </div>
      </GridContainer>
      <GridContainer justify="center">
        <div style={{ color: '#333333' }}>
          <i>Overview of Yimba Security. Issued Credentials are passed in Step 1 above</i>
        </div>
      </GridContainer>
    </Fragment>
  );
}