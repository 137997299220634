/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { user } = useAuth0();
  let showAdditionalContent = false;
  let showAppleContent = false;
  let standardContent = true;

  if (user) {
    if (user.name === 'samsung@yimba.com') {
      showAdditionalContent = true;
      standardContent=false;
    }
    if (user.name === 'apidocs@yimba.com') {
      showAdditionalContent = true;
      standardContent=false;
    }
    if (user.name === 'apple@yimba.com') {
      showAppleContent = true;
      standardContent=false;
    }
  }

  if(standardContent){
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Navigation"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Apps}
            dropdownList={[
              <Link to="/" className={classes.dropdownLink}>
                Technical Documentation
              </Link>,
              <Link to="/about-yimba" className={classes.dropdownLink}>
                What is Yimba?
              </Link>,
              <a className={classes.dropdownLink} href='https://www.npmjs.com/package/yimba-sdk' target="_blank">
                Yimba SDK
              </a>,
              <Link to="/api-docs" className={classes.dropdownLink}>
                Yimba API's
              </Link>,
              <Link to="/get-setup" className={classes.dropdownLink}>
                Setup & Security Overview
            </Link>,
              <a className={classes.dropdownLink} href='/yimba-demo.mp4' target="_blank">
                View a Demo
          </a>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="https://www.npmjs.com/package/yimba-sdk"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <CloudDownload className={classes.icons} /> Download Yimba SDK
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          {/*<Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>*/}
          <Tooltip
            id="instagram-twitter"
            title="Follow us on twitter"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href=""
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href=""
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href=""
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
          </Tooltip>
        </ListItem>
      </List>
    );
  }

  if (showAdditionalContent) {
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          {showAdditionalContent ? <CustomDropdown
            noLiPadding
            buttonText="Navigation"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Apps}
            dropdownList={[
              <Link to="/" className={classes.dropdownLink}>
                Technical Documentation
              </Link>,
              <Link to="/samsung" className={classes.dropdownLink}>
                Samsung Pay Integration
            </Link>,
              <Link to="/about-yimba" className={classes.dropdownLink}>
                What is Yimba?
              </Link>,
              <a className={classes.dropdownLink} href='https://www.npmjs.com/package/yimba-sdk' target="_blank">
                Yimba SDK
              </a>,
              <Link to="/api-docs" className={classes.dropdownLink}>
                Yimba API's
              </Link>,
              <Link to="/get-setup" className={classes.dropdownLink}>
                Setup & Security Overview
            </Link>,
              <a className={classes.dropdownLink} href='/yimba-demo.mp4' target="_blank">
                View a Demo
          </a>,
            ]}
          /> : <CustomDropdown
              noLiPadding
              buttonText="Navigation"
              buttonProps={{
                className: classes.navLink,
                color: "transparent"
              }}
              buttonIcon={Apps}
              dropdownList={[
                <Link to="/" className={classes.dropdownLink}>
                  Technical Documentation
            </Link>,
                <Link to="/about-yimba" className={classes.dropdownLink}>
                  What is Yimba?
            </Link>,
                <a className={classes.dropdownLink} href='https://www.npmjs.com/package/yimba-sdk' target="_blank">
                  Yimba SDK
            </a>,
                <Link to="/api-docs" className={classes.dropdownLink}>
                  Yimba API's
            </Link>,
                <Link to="/get-setup" className={classes.dropdownLink}>
                  Setup & Security Overview
          </Link>,
                <a className={classes.dropdownLink} href='/yimba-demo.mp4' target="_blank">
                  View a Demo
        </a>,
              ]}
            />}
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="https://www.npmjs.com/package/yimba-sdk"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <CloudDownload className={classes.icons} /> Download Yimba SDK
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          {/*<Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>*/}
          <Tooltip
            id="instagram-twitter"
            title="Follow us on twitter"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href=""
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href=""
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href=""
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
          </Tooltip>
        </ListItem>
      </List>
    );
  }
  if (showAppleContent) {
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Navigation"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Apps}
            dropdownList={[
              <Link to="/" className={classes.dropdownLink}>
                Technical Documentation
              </Link>,
              <Link to="/apple" className={classes.dropdownLink}>
                Apple Pay Integration
            </Link>,
              <Link to="/about-yimba" className={classes.dropdownLink}>
                What is Yimba?
              </Link>,
              <a className={classes.dropdownLink} href='https://www.npmjs.com/package/yimba-sdk' target="_blank">
                Yimba SDK
              </a>,
              <Link to="/api-docs" className={classes.dropdownLink}>
                Yimba API's
              </Link>,
              <Link to="/get-setup" className={classes.dropdownLink}>
                Setup & Security Overview
            </Link>,
              <a className={classes.dropdownLink} href='/yimba-demo.mp4' target="_blank">
                View a Demo
          </a>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="https://www.npmjs.com/package/yimba-sdk"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <CloudDownload className={classes.icons} /> Download Yimba SDK
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          {/*<Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>*/}
          <Tooltip
            id="instagram-twitter"
            title="Follow us on twitter"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href=""
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href=""
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href=""
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
          </Tooltip>
        </ListItem>
      </List>
    );
  }
}
