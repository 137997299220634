import React, { Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Journey from "assets/img/journey.png";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>About Yimba</h2>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <div style={{ color: '#333333' }}>
          Yimba is a digital multimedia and marketing company focused on delivering personalised
          content and services to make mobile payments (Bank Wallets, ApplePay, GooglePay,
          SamsungPay) more relevant and fun for consumers. By driving engagement, Yimba will
          increase the number of wallet transactions and provide invaluable insights into consumer
          preferences.
        </div>
        <br></br>
        <div style={{ color: '#333333' }}>
          Turn every payment into a reason to smile. Personalise your digital card in your wallet
          with a splash of colour, adding images and videos that reflect your passions and interests.
          Have fun, start a conversation, tell your story. Yimba, making payments personal.
        </div>
        <br></br>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, marginBottom: 25 }}>
          <img src={Journey} height='350' alt="Example UX Journey"/>
        </div>
        <br></br>
        <div style={{ color: '#333333', marginBottom: 0 }}>
          The way Yimba partners access our SDKs, the route in, or call to action, will depend on
          the UX requirements of the host digital wallet. The most likely place for the Call to Action
          ‘CTA’, would be a ‘Customise Card Art’ button that is displayed after selecting the card.
          A customer would then enter the journey above. Once card art personalisation is
          completed, the user will be re-directed back to the same area of the wallet from which
          they started. <strong>Note:</strong><i>The header component is customisable which will allow you to
          specify labels, colours and fonts that align more closely to your brand or to allow users
          more in depth personalisation.</i>
        </div>
      </GridContainer>
    </Fragment>
  );
}